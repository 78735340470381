import { render, staticRenderFns } from "./_.vue?vue&type=template&id=2210b5e2&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"
import style0 from "./_.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderLinks: require('/var/www/cicada-idnr/nuxt/components/HeaderLinks/HeaderLinks.vue').default})
