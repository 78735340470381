//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    ancestors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "Page Title Here",
    },
  },
  data() {
    return {
      titleLevel: "h1",
    };
  },
  created() {
    this.titleLevel = `h${this.$props.ancestors.length + 1}`;
  },
  methods: {
    headerLevel(idx) {
      if (idx > 6) {
        idx = 6;
      }
      this.level = `h${idx + 1}`;
      return this.level;
    },
    // titleLevel() {
    //   const num = [...this.level].splice(1).join("");
    //   let numLevel = Number(num) + 1;
    //   if (numLevel > 6) {
    //     numLevel = 6;
    //   }
    //   return `h${numLevel}`;
    // },
  },
};
