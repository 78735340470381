/*
  2WAV INC. PROVIDES THIS SOFTWARE TO YOU AS IS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. 

  © 2020 2wav inc. All rights reserved, except as specifically provided by Agreement.
*/

export default {
  getDocument(content) {
    let doc, jsdom;
    if (process.server) {
      /**
       * NOTE: Using require here because importing causes a weird problem in FireFox
       */
      const JSDOM = require("jsdom").JSDOM;
      jsdom = new JSDOM(content);
      doc = jsdom.window.document;
    } else {
      const domParser = new DOMParser();
      doc = domParser.parseFromString(content, "text/html");
    }
    return doc;
  },
  linkChildPage(content, env) {
    if (!content) {
      return undefined;
    }
    const doc = this.getDocument(content);
    const childPageBlocks = doc.querySelectorAll(".ccchildpage");
    Array.from(childPageBlocks).forEach((b) =>
      this._linkChildPage(doc, b, env)
    );
    // console.log("body", doc.querySelector("body").innerHTML);
    return doc.querySelector("body").innerHTML;
  },
  _linkChildPage(doc, block, env) {
    // console.log("TCL: _linkChildPage -> env", env);
    if (!block) return;
    const urlBase = "/";
    const div = block;
    const h3 = div.querySelector("h3");
    const existingAnchor = h3.querySelector("a");
    if (existingAnchor) {
      const title = existingAnchor.innerHTML;
      let href = existingAnchor.href;
      if (href.includes("wp.cicada-idnr")) {
        href = href.replace("https://wp.cicada-idnr.org/", "");
      }
      if (href.includes("localhost")) {
        href = href.replace("http://localhost:8888/", "");
      }
      href = `${urlBase}${href}`;
      h3.removeChild(existingAnchor);
      h3.innerHTML = title;
      const anchor = doc.createElement("a");
      anchor.href = href;
      anchor.innerHTML = div.innerHTML;
      Array.from(div.children).forEach((c) => c.remove());
      div.prepend(anchor);
      // console.log("href:", anchor.href);
    }
    // console.log("block:", block.innerHTML);
    return block;
  },
  linkChildPageTitles(content, env) {
    if (!content) {
      return undefined;
    }
    const doc = this.getDocument(content);
    const childPageBlocks = doc.querySelectorAll(".ccchildpage");
    Array.from(childPageBlocks).forEach((b) => this._linkTitle(b, env));
    return doc.querySelector("body").innerHTML;
  },
  _linkTitle(block, env) {
    if (!block) return;
    const title = block.querySelector("h3");
    const anchor = title.querySelector("a");
    const wrongUrl = anchor.href;
    const urlParts = wrongUrl.split("/");
    const url = urlParts.filter((e, idx) => idx > 2).join("/");
    let frontEndUrl = "https://cicada-idnr.org/";
    if (env !== "production" && env !== undefined) {
      frontEndUrl = "http://localhost:3000/";
    }
    anchor.href = `${frontEndUrl}${url}`;
    // console.log("href:", anchor.href);
    return block;
  },
  addImgWrapperElement(content) {
    if (!content) {
      return undefined;
    }
    const doc = this.getDocument(content);
    const childPageBlocks = doc.querySelectorAll(".ccchildpage");
    Array.from(childPageBlocks).forEach((b) => this._wrapImg(doc, b));
    return doc.querySelector("body").innerHTML;
  },
  _wrapImg(doc, block) {
    if (!block) return;
    const img = block.querySelector("img");
    if (img) {
      const parent = img.parentElement;
      const alreadyWrapped = Array.from(parent.classList).includes(
        "imgwrapper"
      );
      if (!alreadyWrapped) {
        const div = doc.createElement("div");
        div.className = "imgwrapper";
        div.appendChild(img);
        parent.prepend(div);
      }
    }
    return block;
  },
  addPageTags(content, childPages, env) {
    if (!content) {
      return undefined;
    }
    const doc = this.getDocument(content);
    const childPageBlocks = doc.querySelectorAll(".ccchildpage");
    Array.from(childPageBlocks).forEach((b) =>
      this._addTags(doc, b, childPages)
    );
    return doc.querySelector("body").innerHTML;
  },
  _addTags(doc, block, childPages) {
    const pageTitle = block.querySelector("h3").textContent;
    const page = childPages.find((p) => p.title === pageTitle);
    // console.log(page.tags.edges.map((e) => e.node.name).join(","));
    if (page) {
      if (!block.querySelector(".tag")) {
        const div = doc.createElement("div");
        div.classList.add("tag");
        div.textContent = page.tags.edges.map((e) => e.node.name).join(", ");
        block.append(div);
      }
    }
    return block;
  },
};
