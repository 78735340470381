//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as _flattenDeep from "lodash/flattenDeep";
import * as _uniq from "lodash/uniq";
import DomManipulation from "~/lib/DomManipulation";
import GetPageTree from "~/apollo/queries/getPageTree.gql";
import HeaderLinks from "~/components/HeaderLinks/HeaderLinks";

export default {
  components: {
    HeaderLinks,
  },
  layout: "content",
  data() {
    return {
      filter: null,
    };
  },
  head() {
    return {
      title: "CICADA—Conservation Inclusive Development-" + this.parent + this.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.metaDesc,
        },
        {
          property: "og:title",
          content: "CICADA—Conservation Inclusive Development-" + this.parent + this.title,
        },
        {
          property: "og:description",
          content: this.metaDesc,
        },
        {
          property: "og:image",
          content: this.featuredImageSourceUrl,
        },
        {
          property: "og:url",
          content: this.url,
        },
        {
          property: "og:type",
          content: "article",
        },
      ]
    };
  },
  apollo: {
    /**
     * @returns { object }
     */
    page: {
      query: GetPageTree,
      variables() {
        return { id: this.$route.fullPath };
      },
      update(data) {
        const content = data?.page?.content;
        let newContent = DomManipulation.linkChildPage(
          content,
          process.env.NUXT_ENV
        );
        newContent = DomManipulation.addImgWrapperElement(newContent);
        const childPages = data?.page?.children?.edges.map((e) => e.node);
        if (this.isIndex) {
          newContent = DomManipulation.addPageTags(newContent, childPages);
        }
        if (newContent) {
          data.page.content = newContent;
        }
        return data.page;
      },
    },
  },
  computed: {
    residentialOnly() {
      if (this.$route.fullPath === "/residential/") {
        return true;
      }
      return false;
    },
    isIndex() {
      return (this.$route.fullPath.match(/\//g) || []).length <= 2;
    },
    parent() {
      const text = this.page?.parent?.node?.uri?.toString();
      if (text?.startsWith("/residential")) {
        return "Residential" + "-";
      } else if (text?.startsWith("/commercial")) {
        return "Commercial" + "-";
      } else {
        return "";
      }
    },
    title() {
      return this.page?.title;
    },
    metaDesc() {
      return this.page?.seo?.metaDesc;
    },
    featuredImageSourceUrl() {
      return this.page?.featuredImage?.node?.sourceUrl;
    },
    url() {
      return "https://cicada-idnr.org/" + this.page?.uri;
    },
    ancestors() {
      return this.page?.ancestors?.edges?.map((e) => e.node).reverse() || [];
    },
    childTags() {
      return _uniq(
        _flattenDeep(
          this.page?.children?.edges?.map((e) =>
            e.node?.tags?.edges?.map((e) => e.node.name)
          )
        )
      );
    },
    content() {
      return this.page?.content;
    },
  },
  mounted() {
    if (
      this.$route.path ===
      "/commercial/helpful-information-from-the-residential-section/"
    ) {
      this.$router.push("/residential");
    }
  },
  methods: {
    filterTag(tag) {
      // console.log("filter:", tag);
      const pages = Array.from(document.querySelectorAll(".ccchildpage"));
      pages.forEach((p) => (p.style.display = "block"));

      if (this.filter !== tag) {
        this.filter = tag;
        Array.from(document.querySelectorAll(".ccchildpage .tag")).forEach(
          (e) => {
            if (!e.innerHTML.includes(tag)) {
              const page = e.closest(".ccchildpage");
              if (page) {
                page.style.display = "none";
              }
            }
          }
        );
      } else {
        this.filter = null;
      }
    },
  },
};
